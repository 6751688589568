import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <nav
        className="container-xxl navbar navbar-expand-lg bg-primary navbar-dark sticky-top py-lg-0 px-lg-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <a href="#" className="navbar-brand ms-3 d-lg-none">
          MENU
        </a>
        <button
          type="button"
          className="navbar-toggler me-3"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse navbar-nav"
          id="navbarCollapse"
        >
          <div className=" me-auto p-3 p-lg-0"></div>
          <Link to="/" className="nav-item nav-link d-lg-block">
            Home
          </Link>
          <Link to="/AboutUs" className="nav-item nav-link d-lg-block">
            About Us
          </Link>
          <div className="nav-item dropdown">
            <a
              href="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Programs
            </a>
            <div className="dropdown-menu border-0 rounded-0 rounded-bottom m-0">
              <Link to="/Gallery" className="dropdown-item">
                2020-2021
              </Link>
              <Link to="#" className="dropdown-item">
                2021-2022
              </Link>
            </div>
          </div>
          <Link to="/Commitee" className="nav-item nav-link d-lg-block">
            Committe Member
          </Link>
          <Link to="/LifeMember" className="nav-item nav-link d-lg-block">
            Life Member
          </Link>
          <Link to="/Gallery" className="nav-item nav-link d-lg-block">
            Photo Gallery
          </Link>
          <Link to="/Matrimonial" className="nav-item nav-link d-lg-block">
            Metromonial
          </Link>
          <Link to="/ContactUs" className="nav-item nav-link d-lg-block">
            Contact Us
          </Link>
          <Link to="/BusinessCategory" className="nav-item nav-link d-lg-block">
            Business Directory
          </Link>
        </div>
      </nav>
    </>
  );
}
