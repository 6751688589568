import React, { useState, useEffect } from "react";
import { getRequest } from "../../ApiConfig/ApiConfig";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-bootstrap/Carousel";

export default function Index() {
  const [getNewsData, setNews] = useState([]);
  const [getEventsData, setEvents] = useState([]);
  const [allBusinessCategoryData, setData] = useState([]);
  const [getGallery, setGallery] = useState([]);
  const [getAllMaleMatrimonial, SetMaleMatrimonial] = useState([]);
  const [getAllFeMaleMatrimonial, SetFemaleMatrimonial] = useState([]);

  //settings data

  let evtNumber = 1;
  let newsNumber = 1;
  const currentDate = new Date();

  async function getAllNews() {
    try {
      await getRequest("News/Get/All").then((res) => {
        //console.log(res.data);
        setNews(res.data.data.$values);
      });
    } catch (error) {
      // toast.error('Something went wrong, please contact to administrator')
    }
  }
  async function getAllEvents() {
    try {
      await getRequest("Events/Get/All").then((res) => {
        //console.log('ddat',res.data.data)
        setEvents(res.data.data.$values);
      });
    } catch (error) {
      // toast.error('Something went wrong, please contact to administrator')
    }
  }
  async function getAllBusinessCategory() {
    try {
      await getRequest("BusinessCategory/Get/All").then((res) => {
        //console.log('Apires', res.data)
        setData(res.data.$values);
      });
    } catch (error) {
      // toast.error('Something went wrong, please contact to administrator')
    }
  }
  async function getAllGallery() {
    try {
      await getRequest("Gallery/GetWebsite/All").then((res) => {
        //console.log(res.data)
        setGallery(res.data.$values);
      });
    } catch (error) {
      // toast.error('Something went wrong, please contact to administrator')
    }
  }
  async function GetAllBymaleMatrimonial() {
    try {
      await getRequest("Matrimonial/Get/GetAllByMale").then((res) => {
        //console.log("Apires1", res.data);
        SetMaleMatrimonial(res.data.$values);
      });
    } catch (error) {
      // toast.error('Something went wrong, please contact to administrator')
    }
  }
  async function GetAllByFemaleMatrimonial() {
    try {
      await getRequest("Matrimonial/Get/GetAllByFemale").then((res) => {
        //console.log("Apires2", res.data);
        SetFemaleMatrimonial(res.data.$values);
      });
    } catch (error) {
      // toast.error('Something went wrong, please contact to administrator')
    }
  }
  useEffect(() => {
    getAllNews();
    getAllEvents();
    getAllBusinessCategory();
    getAllGallery();
    GetAllBymaleMatrimonial();
    GetAllByFemaleMatrimonial();
  },[]);

  return (
    <>
      {" "}
      <div className="container-xxl p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="../../../assets/img/newBanner1.png"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3></h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="../../../assets/img/newBanner2.png"
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3></h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="../../../assets/img/newBanner3.png"
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3></h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="container-xxl py-5">
        <div className="container-fluid">
          <div className="row g-5">
            <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Events
              </h6>
              {getEventsData.map((item, index) =>
                getEventsData.length > 0 ? (
                  <div key={index}>
                    <h6 className="mt-1">
                      {evtNumber++}.{item.title}
                      &nbsp;
                      <u>
                        <Link to={`/EventsDetails/${item.id}`} className="">
                          more
                        </Link>
                      </u>
                    </h6>

                    {/* <p>Describe for more</p> */}
                  </div>
                ) : (
                  <div>
                    <p>No data Found</p>
                  </div>
                )
              )}
            </div>
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h6 className="section-title bg-white text-start text-primary pe-3">
                  Latest News
                  {/* {new Date().toLocaleString()} */}
                </h6>
                <br />
                <span className="text-muted">
                  Today: {currentDate.toLocaleDateString()}
                </span>
                {getNewsData.map((item, index) => {
                  if (
                    new Date(item.startDate).toLocaleDateString() >=
                      currentDate.toLocaleDateString() &&
                    new Date(item.endDate).toLocaleDateString() >=
                      currentDate.toLocaleDateString()
                  ) {
                    //console.log("Hello " , new Date(item.startDate).toLocaleString() + new Date().toLocaleString());

                    return (
                      <div key={index}>
                        <h6 className="display">
                          {newsNumber++}. {item.title}
                        </h6>
                        {/* <p>Describe for more</p> */}
                        <Link
                          to={`/NewsDetails/${item.id}`}
                          className="btn btn-primary rounded-pill"
                        >
                          Read More
                        </Link>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-3">
        <div className="container">
          <div
            className="text-center mx-auto mb-3 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ max_width: "600px" }}
          >
            <h5 className="section-title bg-white text-center text-primary px-3">
              Business
            </h5>
          </div>
          {allBusinessCategoryData.length && (
            <OwlCarousel
              items={3}
              className="owl-theme project-carousel wow fadeInUp"
              lazyLoad
              loop
              nav
              margin={8}
              autoplay={true}
              data-wow-delay="0.1s"
              responsive={{
                320: {
                items: 1,
              },
              700: {
                items: 2,
              }, 
              1000: {
                items: 3,
              },            
            }}
            >
              {allBusinessCategoryData.map((item, index) =>
                allBusinessCategoryData.length > 0 ? (
                  <div
                    className="project-item border rounded h-100 p-4"
                    key={index}
                  >
                    <div className="position-relative mb-4">
                      <img
                        className="img-fluid rounded"
                        src={item.imageSrc}
                        alt=""
                        style={{ height: "250px", width: "100%" }}
                      />
                      {/* <a href={item.imageSrc} data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a> */}
                      <Link
                        className=""
                        to={`/BusinessDetails/${item.id}`}
                        state={{ BusinessName: item.name }}
                      ></Link>
                    </div>
                    <h6 className="text-center">{item.name}</h6>
                    {/* <span>Agency for design and development</span> */}
                  </div>
                ) : (
                  <div>No data Found</div>
                )
              )}
            </OwlCarousel>
          )}
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ max_width: "600px" }}
          >
            <h5 className="section-title bg-white text-center text-primary px-3">
              Gallery
            </h5>
            <h3 className="display mb-4">Events Celebrations</h3>
          </div>

          <div className="row g-4">
            {getGallery.map((item, index) =>
              getGallery.length > 0 ? (
                <div
                  className="col-lg-4 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  key={index}
                >
                  <Link
                    className="service-item d-block rounded text-center h-100 p-4"
                    to={`/GalleryDetails/${item.id}`}
                    state={{ GalleryName: item.title }}
                  >
                    <img
                      className="img-fluid rounded mb-4"
                      src={item.imageSrc}
                      style={{ height: "250px", width: "100%" }}
                      alt=""
                    />
                    <h4 className="mb-0">{item.title}</h4>
                  </Link>
                </div>
              ) : (
                <div>No data Found</div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="container-xxl py-1">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ max_width: "600px" }}
          >
            <h5 className="section-title bg-white text-center text-primary px-3">
              Matrimonial
            </h5>
            <h3 className="display mb-0">Find Your Perfect Match</h3>
            <div className="mt-0" style={{ float: "right" }}>
              <Link className="" to={`/matrimonial`}>
                View all
              </Link>
            </div>
          </div>

          <OwlCarousel
            items={3}
            className="owl-theme testimonial-carousel wow fadeInUp"
            loop
            nav
            margin={8}
            autoplay
            lazyLoad
            rtl="false"
            data-wow-delay="0.1s"
            responsive={{
              320: {
                items: 1,
              },
              700: {
                items: 2,
              },  
              1000: {
                items: 3,
              },              
            }}
          >
            {getAllMaleMatrimonial.map((item, index) =>
              getAllMaleMatrimonial.length > 0 ? (
                <div
                  className="testimonial-item bg-light rounded p-4 border"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <img
                      className="flex-shrink-0 rounded-circle border p-1"
                      src={item.profileImageSrc}
                      alt=""
                    />
                    <div className="ms-4">
                      <h5 className="mb-1">{item.fullName}</h5>
                      <span>{item.occupation}</span>
                    </div>
                  </div>
                  <p className="mb-0">
                    <label>Address:&nbsp; </label>
                    {item.address}
                  </p>
                  <p className="mb-0">
                    <label>Date of Birth:&nbsp; </label>
                    {item.birthDate != null
                      ? new Date(item.birthDate).toLocaleDateString()
                      : ""}
                  </p>
                  <Link
                    className=""
                    to={`/MatrimonialDetailsNew/${item.id}`}                   
                  >
                    View More
                  </Link>
                </div>
              ) : (
                <div> No data Found</div>
              )
            )}
          </OwlCarousel>
          <br />

          <OwlCarousel
            items={3}
            className="owl-theme testimonial-carousel wow fadeInUp"
            loop
            nav
            margin={8}
            autoplay
            lazyLoad
            data-wow-delay="0.1s"
            responsive={{
              320: {
                items: 1,
              },
              700: {
                items: 2,
              }, 
              1000: {
                items: 3,
              },             
            }}
          >
            {getAllFeMaleMatrimonial.map((item, index) =>
              getAllFeMaleMatrimonial.length > 0 ? (
                <div
                  className="testimonial-item bg-light rounded p-4 border"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <img
                      className="flex-shrink-0 rounded-circle border p-1"
                      src={item.profileImageSrc}
                      alt=""
                    />
                    <div className="ms-4">
                      <h5 className="mb-1">{item.fullName}</h5>
                      <span>{item.occupation}</span>
                    </div>
                  </div>
                  <p className="mb-0">
                    <label>Address:&nbsp; </label>
                    {item.address}
                  </p>
                  <p className="mb-0">
                    <label>Date of Birth:&nbsp; </label>
                    {item.birthDate != null
                      ? new Date(item.birthDate).toLocaleDateString()
                      : ""}
                  </p>
                  <Link
                    className=""
                    to={`/MatrimonialDetailsNew/${item.id}`}                    
                  >
                    View More
                  </Link>
                </div>
              ) : (
                <div> No data Found</div>
              )
            )}
          </OwlCarousel>
          {/* <OwlCarousel
            items={3}
            className="owl-theme testimonial-carousel wow fadeInUp"
            loop
            nav
            margin={8}
            autoplay
            lazyLoad
            data-wow-delay="0.1s"
          >
            {getMatrimonial.map((item, index) =>
              getMatrimonial.length > 0 ? (
                item.gender === "female" ? (
                  <div
                    className="testimonial-item bg-light rounded p-4 border"
                    key={index}
                  >
                    <p>{item.gender}</p>
                    <div className="d-flex align-items-center">
                      <img
                        className="flex-shrink-0 rounded-circle border p-1"
                        src={item.profileImageSrc}
                        alt=""
                      />
                      <div className="ms-4">
                        <h5 className="mb-1">
                          {item.firstName + " " + item.middleName}
                        </h5>
                        <span>{item.occupation}</span>
                      </div>
                    </div>
                    <p className="mb-0">
                      <label>Address:&nbsp; </label>
                      {item.address}
                    </p>
                    <p className="mb-0">
                      <label>Age:&nbsp; </label>
                      {item.age}
                    </p>
                    <Link
                      className=""
                      to={`/MatrimonialDetails/${item.id}`}
                      state={{ MatrimonialName: item.name }}
                    >
                      View More
                    </Link>
                  </div>
                ) : (
                  <div
                    className="testimonial-item bg-light rounded p-4 border"
                    key={index}
                  >
                    <p>{item.gender}</p>
                    <div className="d-flex align-items-center">
                      <img
                        className="flex-shrink-0 rounded-circle border p-1"
                        src={item.profileImageSrc}
                        alt=""
                      />
                      <div className="ms-4">
                        <h5 className="mb-1">
                          {item.firstName + " " + item.middleName}
                        </h5>
                        <span>{item.occupation}</span>
                      </div>
                    </div>
                    <p className="mb-0">
                      <label>Address:&nbsp; </label>
                      {item.address}
                    </p>
                    <p className="mb-0">
                      <label>Age:&nbsp; </label>
                      {item.age}
                    </p>
                    <Link
                      className=""
                      to={`/MatrimonialDetails/${item.id}`}
                      state={{ MatrimonialName: item.name }}
                    >
                      View More
                    </Link>
                  </div>
                )
              ) : (
                <div> No data Found</div>
              )
            )}
          </OwlCarousel>

          <OwlCarousel
            items={getMatrimonial.length - }
            className="owl-theme testimonial-carousel wow fadeInUp"
            loop
            nav
            margin={8}
            autoplay
            lazyLoad
            data-wow-delay="0.1s"
          >
            {getMatrimonial.map((item, index) =>
              getMatrimonial.length > 0 ? (
                item.gender === "male" ? (
                  <div
                    className="testimonial-item bg-light rounded p-4 border"
                    key={index}
                  >
                    <p>{item.gender}</p>
                    <div className="d-flex align-items-center">
                      <img
                        className="flex-shrink-0 rounded-circle border p-1"
                        src={item.profileImageSrc}
                        alt=""
                      />
                      <div className="ms-4">
                        <h5 className="mb-1">
                          {item.firstName + " " + item.middleName}
                        </h5>
                        <span>{item.occupation}</span>
                      </div>
                    </div>
                    <p className="mb-0">
                      <label>Address:&nbsp; </label>
                      {item.address}
                    </p>
                    <p className="mb-0">
                      <label>Age:&nbsp; </label>
                      {item.age}
                    </p>
                    <Link
                      className=""
                      to={`/MatrimonialDetails/${item.id}`}
                      state={{ MatrimonialName: item.name }}
                    >
                      View More
                    </Link>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <div> No data Found</div>
              )
            )}
          </OwlCarousel> */}
        </div>
      </div>
      <div className="container-xxl">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ max_width: "600px" }}
          >
            <h5 className="section-title bg-white text-center text-primary px-3">
              Publication
            </h5>
            <h3 className="display mb-4">News/Event</h3>
            <div
              className="project-item col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="position-relative mb-4 service-item d-block rounded text-center">
                <img
                  className="img-fluid rounded"
                  src="/assets/img/snehmilan 2000.jpeg"
                  alt=""
                  style={{ height: "250px", width: "100%" }}
                />
                <a
                  href="/assets/img/snehmilan 2000.jpeg"
                  data-lightbox="project"
                >
                  <i className="fa fa-eye fa-2x"></i>
                </a>
              </div>
              <h6 className="text-center">Publication</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
