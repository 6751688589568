import React from "react";
import Header from "./Header";
import Home from "../Component/Home/index";
import Footer from "./Footer";
import AppContent from "./AppContent";
import { useState, useEffect } from "react";
import { getRequest } from "../ApiConfig/ApiConfig";

export default function Layout() {
  const [address, setAddress] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [faceBookLink, setFacebookLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");

  async function getAllEventById() {
    try {
      await getRequest("Settings/Get").then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          setAddress(res.data.data.address);
          setAddress(res.data.data.address);
          setMobileNo(res.data.data.mobileNo);
          setEmail(res.data.data.email);
          setLinkedinLink(res.data.data.linkedinLink);
          setFacebookLink(res.data.data.faceBookLink);
          setYoutubeLink(res.data.data.youtubeLink);
          setTwitterLink(res.data.data.twitterLink);
        }
      });
    } catch (error) {
      //toast.error("Something went wrong, please contact to administrator");
    }
  }

  useEffect(() => {
    getAllEventById();
  }, []);

  return (
    <>
      <div className="container-xxl py-2 px-3 wow fadeIn" data-wow-delay="0.1s">
        <div className="row align-items-center top-bar">
          <div className="row mb-0">
            <div className="col-md-4">
              <h5
                className="text-bold text-center text-light"
                style={{
                  background: "linear-gradient(to right,white,#007bff,white)",
                }}
              >
                <b>સેવા...</b>
              </h5>
            </div>
            <div className="col-md-4">
              <h5
                className="text-bold text-center text-light"
                style={{
                  background: "linear-gradient(to right,white,#007bff,white)",
                }}
              >
                <b>સહકાર...</b>
              </h5>
            </div>
            <div className="col-md-4">
              <h5
                className="text-bold text-center text-light"
                style={{
                  background: "linear-gradient(to right,white,#007bff,white)",
                }}
              >
                <b>સંગઠન...</b>
              </h5>
            </div>
            {/* <img
                  className = "imge-fulid"
                  src="./assets/img/baner-top.png"
                  alt=""
                /> */}
          </div>
          <div className="col-lg-6 col-md-12 text-center text-lg-start">
            <div className="row">
              <div className="col-md-1 mt-2">
                <img
                  src="./assets/img/logo2.png"
                  alt=""
                  style={{ height: "60px", width: "60px" }}
                />
              </div>
              <div className="col-md-11 mt-2">
                <a href="" className="navbar-brand m-0 p-0 ">
                  <h4 className="fw-bold m-0">
                    &nbsp;&nbsp;પ્રજાપતિ પ્રગતિ મંડળ, રાણીપ - અમદાવાદ
                  </h4>
                </a>
                <p className="text-primary fs-6 mt-1">
                  &nbsp; &nbsp;(કાર્યક્ષેત્ર: રાણીપ-ન્યુ રાણીપ) &nbsp;
                  <span className="text-secondary text-bold">
                    <b>સ્થાપના વર્ષ: 1988, રજી. નં. : ઇ-8906</b>
                  </span>
                </p>
              </div>
            </div>

            {/* <a href="" className="navbar-brand m-0 p-0 mb-0">
              <h4 className="fw-bold m-0 mb-0">
                &nbsp;પ્રજાપતિ પ્રગતિ મંડળ, રાણીપ, અમદાવાદ
              </h4>
            </a>
            <p className="text-primary fs-6 mt-0 ml-4">
              &nbsp; &nbsp;(કાર્યક્ષેત્ર: રાણીપ-ન્યુ રાણીપ)
            </p> */}
          </div>
          <div className="col-lg-6 col-md-7 d-none d-lg-block">
            <div className="row">
              <div className="col-6">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="flex-shrink-0 btn-lg-square border rounded-circle">
                    <i className="fa fa-phone text-primary"></i>
                  </div>
                  <div className="ps-3">
                    <p className="mb-2">Call Us (મંત્રીશ્રી)</p>
                    <h6 className="mb-0">+91 {mobileNo}</h6>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="flex-shrink-0 btn-lg-square border rounded-circle">
                    <i className="far fa-envelope text-primary"></i>
                  </div>
                  <div className="ps-3">
                    <p className="mb-2">Email Us</p>
                    <h6 className="mb-0">{email}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <AppContent />
      <div
        className="container-xxl bg-dark text-body footer mt-5 pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-xxl py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Address</h5>
              <p className="mb-2 text-light">
                <i className="fa fa-map-marker-alt me-3"></i>
                {address}
              </p>
              <p className="mb-2 text-light">
                <i className="fa fa-phone-alt me-3"></i>+91 {mobileNo}
              </p>
              <p className="mb-2 text-light">
                <i className="fa fa-envelope me-3"></i>
                {email}
              </p>
              <div className="d-flex pt-2">
                <a
                  className="btn btn-square btn-outline-secondary rounded-circle me-1"
                  href={twitterLink}
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="btn btn-square btn-outline-secondary rounded-circle me-1"
                  href={faceBookLink}
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  className="btn btn-square btn-outline-secondary rounded-circle me-1"
                  href={youtubeLink}
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  className="btn btn-square btn-outline-secondary rounded-circle me-0"
                  href={linkedinLink}
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <a className="btn btn-link text-light" href="/AboutUs">
                About Us
              </a>
              <a className="btn btn-link text-light" href="/ContactUs">
                Contact Us
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Newsletter</h5>
              <p className="text-light">Prajapati Pragati Mandal</p>
              <div
                className="position-relative mx-auto"
                style={{ max_width: "400px" }}
              >
                <input
                  className="form-control bg-transparent border-secondary w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-xxl copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-light text-md-start mb-3 mb-md-0">
                &copy; <a href="#">Prajapati Pragati Mandal</a>, All Right
                Reserved.
              </div>
              <div className="col-md-6 text-center text-light text-md-end">
                Developed by&nbsp;
                <a href="https://dharmendraprajapati.com/" target="_blank">
                  Dharmendra
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
