import React from "react";

const Home = React.lazy(() => import("./Component/Home/index"));
const ContactUs = React.lazy(() => import("./Component/ContactUs/index"));
const AboutUs = React.lazy(() => import("./Component/AboutUs/index"));
const Gallery = React.lazy(() => import("./Component/Gallery/index"));
const GalleryDetails = React.lazy(() =>
  import("./Component/Gallery/GalleryDetails")
);
const BusinessDetails = React.lazy(() => import("./Component/Business/index"));
const NewsDetails = React.lazy(() => import("./Component/News/NewsDetails"));
const EventsDetails = React.lazy(() =>
  import("./Component/Events/EventsDetails")
);
const BusinessCategory = React.lazy(() =>
  import("./Component/Business/BusinessCategory")
);
const Commitee = React.lazy(() => import("./Component/Committee/Index"));
const Matrimonial = React.lazy(() => import("./Component/Matrimonial/index"));
const MatrimonialDetailsNew = React.lazy(() =>
  import("./Component/Matrimonial/MatrimonialDetailsNew")
);
const LifeMember = React.lazy(() => import("./Component/LifeMember/Index"));
const LifeMemberDetails = React.lazy(() =>
  import("./Component/LifeMember/LifememberDetails")
);

const routes = [
  { path: "/", exact: true, name: "Home", element: Home },
  { path: "/ContactUs", exact: true, name: "ContactUs", element: ContactUs },
  { path: "/AboutUs", exact: true, name: "AboutUs", element: AboutUs },
  { path: "/Gallery", exact: true, name: "Gallery", element: Gallery },

  {
    path: "/GalleryDetails/:Id",
    exact: true,
    name: "GalleryDetails",
    element: GalleryDetails,
  },
  {
    path: "/BusinessDetails/:Id",
    exact: true,
    name: "BusinessDetails",
    element: BusinessDetails,
  },
  {
    path: "/NewsDetails/:Id",
    exact: true,
    name: "NewsDetails",
    element: NewsDetails,
  },
  {
    path: "/EventsDetails/:Id",
    exact: true,
    name: "EventsDetails",
    element: EventsDetails,
  },
  {
    path: "/BusinessCategory",
    exact: true,
    name: "BusinessCategory",
    element: BusinessCategory,
  },
  {
    path: "/MatrimonialDetailsNew/:Id",
    exact: true,
    name: "MatrimonialDetailsNew",
    element: MatrimonialDetailsNew,
  },
  { path: "/Commitee", exact: true, name: "Commitee", element: Commitee },
  // { path: '/Home',  name: 'Home' },
  //   { path: '/dashboard', name: 'Home', element: Dashboard },
  {
    path: "/LifeMember",
    exact: true,
    name: "LifeMember",
    element: LifeMember,
  },
  { path: "/Matrimonial", exact: true, name: "Matrimonial", element: Matrimonial },
  {
    path: `/LifememberDetails/:${encodeURIComponent("Id")}`,
    name: "LifeMemberDetails",
    element: LifeMemberDetails,
  },
];

export default routes;
