import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = "https://api.prajapatipragatimandal.org/api";
//axiosClient.defaults.baseURL = "https://localhost:44340/api";
axiosClient.defaults.headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
  "Content-Type": "application/json",
  Accept: "application/json",
};

//All request will wait 2 seconds before timeout
//axiosClient.defaults.timeout = 200000

axiosClient.defaults.withCredentials = true;

export function getRequest(URL) {
  return axiosClient.get(`/${URL}`).then((response) => response);
}
export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}
